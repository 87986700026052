import revive_payload_client_2MigqEpTCC from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._0e77234ab078cb5a1f14e7c1be652d0d/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IaliG0VdLu from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._0e77234ab078cb5a1f14e7c1be652d0d/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_M0xPYmXFCK from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._0e77234ab078cb5a1f14e7c1be652d0d/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_OmPptilVvS from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._0e77234ab078cb5a1f14e7c1be652d0d/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_azWqIy8i0G from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._0e77234ab078cb5a1f14e7c1be652d0d/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NdC4eDL5hV from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._0e77234ab078cb5a1f14e7c1be652d0d/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_NMPj4gIqwB from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._0e77234ab078cb5a1f14e7c1be652d0d/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_NogW8P70xW from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._0e77234ab078cb5a1f14e7c1be652d0d/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/workspace/kody/hi3/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_U850ugNNwl from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._0e77234ab078cb5a1f14e7c1be652d0d/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_2MigqEpTCC,
  unhead_IaliG0VdLu,
  router_M0xPYmXFCK,
  payload_client_OmPptilVvS,
  navigation_repaint_client_azWqIy8i0G,
  check_outdated_build_client_NdC4eDL5hV,
  view_transitions_client_NMPj4gIqwB,
  chunk_reload_client_NogW8P70xW,
  components_plugin_zlvi6dcIsi,
  prefetch_client_U850ugNNwl
]